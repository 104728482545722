import React, { useState } from 'react';
import { apiURL } from "../../services/apiUrl";

const ImageUploader = ({ eventId, type, refr, refresh }) => {
    const [image, setImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(reader.result);
            setErrorMessage('');
        };

        if (file) {
            if (file.type === 'image/png') {
                if (file.size < 2 * 1024 * 1024) {
                    reader.readAsDataURL(file);
                } else {
                    setErrorMessage('La imagen es demasiado grande (máximo 2 MB)');
                }
            } else {
                setErrorMessage('El archivo no es de tipo PNG');
            }
        }
    };

    const handleUpload = () => {
        if (image) {
            fetch(`${apiURL}/api/upload-img`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    image: image,
                    eventId: eventId,
                    type: type
                })
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Imagen subida al servidor', data);
                    refresh(refr + 1);
                })
                .catch(error => {
                    console.error('Error al subir la imagen:', error);
                });
        } else {
            setErrorMessage('No hay ninguna imagen para subir');
        }
    };

    return (
        <div className='imgupload'>
            {image && (
                <div>
                    <img src={image} alt="preview" style={{ height: '150px', width: 'auto' }} />
                </div>
            )}
            <input type="file" onChange={handleImageChange} />
            {image && (
                <button onClick={handleUpload}>
                    <p>Subir Imagen</p>
                </button>
            )}
            {errorMessage && (
                <p style={{ color: 'red', fontSize: '0.9rem' }}>{errorMessage}</p>
            )}
        </div>
    );
};

export default ImageUploader;
