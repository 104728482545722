import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { apiURL } from "../../services/apiUrl";
import "./Report.css"

import Container from '@mui/material/Container'
import { FormControlLabel, Box, Grid } from "@mui/material";
import PieChart from 'react-simple-pie-chart';

const ReportContainer = () => {
    const [event, setEvent] = useState('');
    const [reportStats, setReportStats] = useState();
    const [historySetups, setHistorySetups] = useState();
    const [efectividadSetups, setEfectividadSetups] = useState();
    const { eid } = useParams();

    const eventById = async () => {
        const response = await fetch(`${apiURL}/api/tournaments/1/events/${eid}`);
        const result = await response.json();
        setEvent(result)
    }

    const getReportStats = async () => {
        const response = await fetch(`${apiURL}/api/report/${eid}`);
        const result = await response.json();
        // console.log(result)
        setReportStats(result)
    }

    const getHistorySetups = async () => {
        const response = await fetch(`${apiURL}/api/estaciones/event/${eid}/history`);
        const result = await response.json();
        // console.log(result)
        setHistorySetups(result);
    }

    function calcularDiferenciaTiempo(tiempoInicio, tiempoFin) {
        const inicio = new Date(tiempoInicio);
        const fin = new Date(tiempoFin);
        return (fin - inicio) / 1000; // Diferencia en milisegundos
    }

    const calcularEfectividad = () => {
        if (reportStats && historySetups) {
            let historiales = [];
            historySetups.forEach((hs) => {
                const historys = hs.history.filter((h) => h.state === "ocupado")

                const sumaDiferencias =
                    historys.reduce((acumulador, item) => {
                        if (item.tiempoInicio && item.tiempoFin) {
                            const diferencia = calcularDiferenciaTiempo(item.tiempoInicio, item.tiempoFin);
                            return acumulador + diferencia;
                        }
                        return acumulador;
                    }, 0);

                historiales.push({ setup: hs.setup, tiempoOcupado: sumaDiferencias })
            })

            console.log(historiales, reportStats.tiempoTotal.total)

            const efect = historiales.map((h) => [{
                color: '#8571cf',
                value: h.tiempoOcupado,
            },
            {
                color: '#000000',
                value: reportStats.tiempoTotal.total - h.tiempoOcupado,
            }]
            )

            // console.log(efect)

            setEfectividadSetups(efect)
        }
    }

    useEffect(() => {
        eventById();
        getReportStats();
        getHistorySetups();
    }, [eid]);

    useEffect(() => {
        calcularEfectividad();
    }, [reportStats, historySetups]);

    return (
        <Box className="report">
            <Grid container spacing={4} padding={'32px'} margin={'-16px'}>
                <Grid item columnSpacing={1} rowSpacing={3} xs={12} sm={4} md={3} padding={'32px'} >
                    <Grid item xs={12} >
                        <h1 className=""> Reporte del Evento </h1>
                        {event &&
                            <>
                                <h2> {event.name} </h2>
                                <div className="imagen">
                                    <img src={event.videogame.images[0].url} alt="juego" />
                                </div>
                                {/* <h2> JUEGO: {event.videogame.name} </h2> */}
                            </>
                        }

                    </Grid>
                </Grid>
                <Grid container xs={12} md={9} padding={'0px'} margin={'0px'} className="stats">

                    <Grid padding={'32px'} xs={12} sm={4} md={3} className="stat-element">
                        <h2> Cantidad de inscriptos </h2>
                        {event ? <h3> » {event.numEntrants} </h3> : <h3> Cargando... </h3>}
                    </Grid>

                    <Grid padding={'32px'} xs={12} sm={4} md={3} className="stat-element">
                        <h2> Cantidad de partidas jugadas </h2>
                        {reportStats ? <h3> » {reportStats.cantPartidas} </h3> : <h3> Cargando... </h3>}
                    </Grid>

                    <Grid padding={'32px'} xs={12} sm={4} md={3} className="stat-element">
                        <h2> Tiempo total de evento </h2>
                        {reportStats ? (
                            <>
                                <h3>
                                    {`» ${reportStats.tiempoTotal.days} días,
                                    ${reportStats.tiempoTotal.hours} horas,
                                    ${reportStats.tiempoTotal.minutes} minutos,
                                    ${reportStats.tiempoTotal.seconds} segundos`}
                                </h3>
                                {/* <h3> {`» ${reportStats.tiempoTotal.total} en total (segundos)`} </h3> */}
                            </>
                        ) : (
                            <h3> Cargando... </h3>
                        )}
                    </Grid>
                    <Grid padding={'32px'} xs={12} sm={4} md={3} className="stat-element">
                        <h2> Tiempo medio de duración de partida </h2>
                        {reportStats ? (
                            <h3>
                                {`» ${reportStats.tiempoMedioPartida.daysProm} días,
                                    ${reportStats.tiempoMedioPartida.hoursProm} horas,
                                    ${reportStats.tiempoMedioPartida.minutesProm} minutos,
                                    ${reportStats.tiempoMedioPartida.secondsProm} segundos`}
                            </h3>
                        ) : (
                            <h3> Cargando... </h3>
                        )}
                    </Grid>

                    {(reportStats && efectividadSetups) &&
                        efectividadSetups.map((e, index) => (

                            <Grid key={index} padding={'32px'} xs={12} sm={4} md={3} className="stat-element">
                                <h2> Efectividad de ocupación setup N° {index + 1}  </h2>
                                <h3> » {((e[0].value / reportStats.tiempoTotal.total) * 100).toFixed(2)} % </h3>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <PieChart
                                        slices={e}
                                        borderWidth={1}
                                    />
                                </Box>
                            </Grid>

                        ))
                    }


                </Grid>
            </Grid>

        </Box>
    )
}

export default ReportContainer;