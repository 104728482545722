import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiURL } from "../../services/apiUrl";
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'


const PaginationComponent = ({ phases, actualizarSets, iniciandoEvento }) => {

    function obtenerIndiceGanador(slots, winnerId) {
        for (let i = 0; i < slots.length; i++) {
            if (slots[i].entrant.id === winnerId) {
                return i;
            }
        }
        return -1; // Si no se encuentra el ganador en la matriz
    }

    function invertir(index){
        return index === 0 ? 1 : 0;
    }

    function resultado(set) {
        if (!set.slots[0].entrant) {
            return ["TBD", "TBD", "-", "-"]
        }
        if (!set.slots[1].entrant) {
            return [set.slots[0].entrant.name, "TBD", "-", "-"]
        }
        if (!set.displayScore) {
            return [set.slots[0].entrant.name, set.slots[1].entrant.name, "-", "-"] 
        }
        if (set.displayScore === "DQ") {
            const winner = obtenerIndiceGanador(set.slots, set.winnerId)
            return([
                set.slots[winner].entrant.name,
                set.slots[invertir(winner)].entrant.name,
                "W",
                "DQ"
            ])    
        }
        return ([
            set.slots[1].entrant.name,
            set.slots[0].entrant.name,
            set.displayScore.split(" - ")[0],
            set.displayScore.split(" - ")[1]
        ])
    }

    const [sets, setSets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPhase, setCurrentPhase] = useState(phases[0].id);
    const [totalPages, setTotalPages] = useState(0);
    const { eid } = useParams();

    const fetchSets = async (page, phase) => {
        try {
            const query = `${apiURL}/api/event/${eid}/sets?page=${page}&phaseId=${phase}`
            const response = await fetch(query);
            const data = await response.json();
            setSets(data.phases[0].sets.nodes);
            setTotalPages(data.phases[0].sets.pageInfo.totalPages);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSets(currentPage, currentPhase);
    }, [currentPage, currentPhase, actualizarSets, iniciandoEvento]);

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const goToPhase = (phase) => {
        setCurrentPhase(phase)
        setCurrentPage(1)
    }

    const renderSets = () => {
        if (sets.length === 0) { return <div> Cargando partidas... </div>; }

        return (
            <Grid container spacing={4} className='partidas'>
              {sets.map((set) => (
                <Grid item xs={12} md={4} sm={6}>
                    <Box className='partida'>
                        <Box className="participantes">
                            <h6> {resultado(set)[0]} </h6>
                            <h6> {resultado(set)[1]} </h6>
                        </Box>
                        <Box className="resultados">
                            <h6> {resultado(set)[2]} </h6>
                            <h6> {resultado(set)[3]} </h6>
                        </Box>
                    </Box>
                </Grid>
                ))}
            </Grid>
        );
    };

    const renderPagination = () => {
        if (totalPages === 0) { return null; }

        return (
            <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <li
                        key={page}
                        className={currentPage === page ? 'active' : ''}
                        onClick={() => goToPage(page)}
                    >
                        {page}
                    </li>
                ))}
            </ul>
        );
    };

    const renderFilter = () => {
        return (
            phases.map((p) => <button
                key={p.id}
                className={currentPhase === p.id ? 'active' : ''}
                onClick={() => goToPhase(p.id)}
            >
                {p.name}
            </button>)
        )
    }

    return (
        <>
            <Box className='filter'>
                <h5> Todas las partidas </h5>
                {renderFilter()}
            </Box>

            {renderSets()}
            {renderPagination()}


        </>
    );
};

export default PaginationComponent;