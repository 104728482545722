import React, { useState, useEffect } from 'react';
import { apiURL } from "../../../services/apiUrl";
import Swal from 'sweetalert2';

// mui
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DialogContent, DialogContentText } from '@mui/material/';
import DialogTitle from '@mui/material/DialogTitle';


const AddSetUpComponent = ({ setups, sendData, eventId }) => {

    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState(0)

    const abrirDialog = () => {
        setOpen(true)
        const numero = obtenerNumero()
        setNumber(numero)
        sendData(true)
    }

    const obtenerNumero = () => {
        // let numero = 1;
        
        // return numero
        if (!setups.length) return 1;
        const numeroList = setups.map((setup) => {
            return setup.numero
        })
        const mayor = Math.max(...numeroList)
        return mayor + 1;
    }

    const cerrarDialog = async (agregar) => {
        if (agregar) {
            await agregarSetUp(number)
            setOpen(false)
            setNumber(0)
            sendData(false)
        }
        else {
            setNumber(0)
            setOpen(false)
            sendData(false)
        }
    }

    const agregarSetUp = async (number) => {
        try {
            await fetch(`${apiURL}/api/estaciones`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    numero: number,
                    event: eventId
                })
            }).then((res) => res.json()).then((result) => {
                if (result.agregado !== 'ok') {
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al agregar SetUp',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        background: '#0f1926'
                    })
                }
            })
        }
        catch {
            Swal.fire({
                title: 'Error',
                text: 'Error al agregar SetUp',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                background: '#0f1926',
                color: '#fff'
            })
        }
    }

    return (
        <>
            <IconButton onClick={() => { abrirDialog(); }}>
                <AddIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        cerrarDialog(false)
                    }
                }}
                maxWidth={'sm'}
                fullWidth={true}
            >

                <DialogTitle> Agregar SetUp </DialogTitle>

                <DialogContent dividers={true}>
                    <DialogContentText> ¿Agregar al evento el setup {number}?  </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => { cerrarDialog(true) }}> Aceptar </Button>
                    <Button onClick={() => { cerrarDialog(false) }}> Cancelar </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};


export default AddSetUpComponent