import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import EventList from '../../components/EventList/EventList'
import "./EventListContainer.css";
import { apiURL } from "../../services/apiUrl";
import Container from '@mui/material/Container'



const EventListContainer = () => {

    const [tournaments, setTournaments] = useState();
    const { tid } = useParams();

    const eventsByOwnerId = async () => {
        const response = await fetch(`${apiURL}/api/tournaments/${tid}/events`);
        const result = await response.json();
        setTournaments(result)
    }

    useEffect(() => {
        eventsByOwnerId();
    }, [tid]);

    return (
        <Container className='events'>
          <Container>
            <h1> Eventos </h1>
          </Container>
          <Container className='event-list'>
            {tournaments ? <EventList data={tournaments} /> : <div className='waiting'> Cargando eventos... </div> }
          </Container>
        </Container>
    )
}

export default EventListContainer