import React, { useState, useEffect } from 'react';
import { apiURL } from "../../../services/apiUrl";
import Swal from 'sweetalert2';

// mui
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const DeleteSetUpComponent = ({setup, sendData}) => {

    const [open, setOpen] = useState(false);


    const abrirDialog = () => {
        setOpen(true)
        sendData(true)
    }

    const cerrarDialog = async (borrar) => {
        if (borrar) {
            await eliminarSetUp(setup._id)
            setOpen(false)
            sendData(false)
        }
        else {
            setOpen(false)
            sendData(false)
        }
    }

    const eliminarSetUp = async (id) => {
        try {
            await fetch(`${apiURL}/api/estaciones/${id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((res) => res.json()).then((result) => {
                if (result.borrado !== 'ok') {
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al eliminar SetUp',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        background: '#0f1926'
                    })
                }
            })
        }
        catch{
            Swal.fire({
                title: 'Error',
                text: 'Error al eliminar SetUp',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                background: '#0f1926',
                color: '#fff'
            })
        }   
    }

    return (
        <>
            <IconButton  onClick={() => {abrirDialog();}}><DeleteIcon /></IconButton>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown'){
                        cerrarDialog(false)
                    }
                }}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogContent dividers={true}>
                    <DialogContentText alignContent={'center'}>
                        ¿Seguro que desea eliminar el setup <strong>{setup.numero}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={() => {cerrarDialog(true)}}>Sí</Button>
                    <Button onClick={() => {cerrarDialog(false)}}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default DeleteSetUpComponent