import { React, useState, useRef, useContext } from 'react';
import "./LogIn.css";
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { apiURL } from "../../services/apiUrl";
import { userContext } from '../../context/UserProvider';
import { json, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken, onMessage } from "firebase/messaging";
import {messaging, app} from '../../firebase';
import { enviarTokenNotificacion, actualizarTokenNotificacion } from '../../services/notifications.service';

const Login = () => {

  const [validoUser, setValidoUser] = useState(true)
  const [validoPass, setValidoPass] = useState(true)
  const [error, setError] = useState(null);
  const user = useRef('')
  const pass = useRef('')

  const { userC, setUserC } = useContext(userContext);

  const navigate = useNavigate();

  const validar = () => {
    return (user.current.value && pass.current.value)
}

  const logIn = () => {
    if (!user.current.value) {
      setValidoUser(false)
    } else {
      setValidoUser(true)
    }
    if (!pass.current.value) {
      setValidoPass(false)
    } else {
      setValidoPass(true)
    }

    if (validar()) {

      const url = `${apiURL}/api/auth/login`;

      const data = {
        email: user.current.value,
        password: pass.current.value
      };

      console.log(data)

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error en la solicitud');
          }
          return response.json();
        })
        .then(data => {
          setUserC(data);
          console.log(data)
          localStorage.setItem('user', JSON.stringify(data));
          navigate("/");
        })
        .then(async () => {
          const msg = messaging;
          const swSubs = await navigator.serviceWorker.register('./firebase-messaging-sw.js', {scope: 'firebase-cloud-messaging-push-scope'})
          const token = await getToken(msg, {vapidKey: 'BJ_jtR4FyWAfl9OhpS23j8NFuB7Ys0aj1I0YAnhRab1V0J2OUiyaps-N7PMkpdhaDjYNDRxkV7I-SlNgB9jzknU', serviceWorkerRegistration: swSubs})
          console.log(token)
          const tokenViejo = localStorage.getItem('notification_token')
          if (!tokenViejo) {
            localStorage.setItem('notification_token', JSON.stringify({token: token}))
            await enviarTokenNotificacion(token)
          } else if (tokenViejo.token !== token) {
            localStorage.setItem('notification_token', JSON.stringify({token: token}))
            await actualizarTokenNotificacion(tokenViejo.token, token)
          }
          onMessage(msg, (payload) => {
              if (payload.data && payload.data.title){
                  Swal.fire(
                      {
                          position: 'top',
                          icon: 'info',
                          title: payload.data.title,
                          text: payload.data.body,
                          showConfirmButton: false,
                          backdrop: false,
                          timer: 3000
                        }
                  )
              } else {
                  Swal.fire(
                      {
                          position: 'top',
                          icon: 'info',
                          title: payload.notification.title,
                          text: payload.notification.body,
                          showConfirmButton: false,
                          backdrop: false,
                          timer: 3000
                        }
                  )
              }
            })
        })
        .catch(error => {
          console.error(error);
          setError('Credenciales inválidas. Inténtelo de nuevo.');
        });
    }
  }



  return (

    <Container maxWidth="sm" className='login-container'>

      <Grid container columnSpacing={1} rowSpacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        textAlign="center"
        wrap="wrap">

        <Grid item xs={12}>
          <h5>Iniciar sesión</h5>
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={!validoUser}
            id="username"
            label="Email"
            className='login-form'
            helperText={validoUser ? null : 'Debe ingresar usuario'}
            focused={!validoUser}
            inputRef={user}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={!validoPass}
            id="password"
            label="Contraseña"
            type='password'
            className='login-form'
            helperText={validoPass ? null : 'Debe ingresar contraseña'}
            focused={!validoPass}
            inputRef={pass}
          />
        </Grid>

        {error && (
          <Grid item xs={12}>
            <p style={{ textAlign: "center", color: "red" }}>{error}</p>
          </Grid>
        )}

        <Grid item xs={4} >
          <Button variant="outlined" color="secondary" href='/register'>
            Ir a Registrarse
          </Button>
        </Grid>

        <Grid item xs={4} >
          <Button variant="outlined" color="primary" href='/'>
            Volver
          </Button>
        </Grid>

        <Grid item xs={4} >
          <Button variant="contained" color="primary" onClick={logIn}>
            Iniciar sesión
          </Button>
        </Grid>

      </Grid>

    </Container>


  );

}

export default Login