import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiURL } from "../../services/apiUrl";
import "./Configurador.css"
import ImageUploader from "../../components/ImageUploader/ImageUploader";

const ConfiguradorContainer = () => {

    const [images, setImages] = useState([])
    const [refresh, setRefresh] = useState(0);

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const eid = query.get("eid");

    const getImages = async (eid) => {
        const response = await fetch(`${apiURL}/api/images/event/${eid}`);
        const result = await response.json();
        console.log(result)
        setImages(result);
    }

    useEffect(() => {
        getImages(eid)
    }, [ refresh ]);

    return (
        <div className="configurador">

            {/* Logo de ORGANIZACIÓN */}
            <div className="organizacion">
                <h1> Logo de Organización </h1>
                <div className="imgdiv">
                    <ImageUploader eventId={eid} type='organization' refr={refresh} refresh={setRefresh} />
                    <div className="imgshow">
                        {images && images.map((i) =>
                            i.type === "organization" ?
                                <img src={i.image} alt="preview" style={{ height: '200px', width: 'auto' }} />
                                : <></>)}
                    </div>
                </div>
            </div>

            {/* Logo de EVENTO */}           
            <div className="evento">
                <h1> Logo de Evento </h1>
                <div className="imgdiv">
                    <ImageUploader eventId={eid} type='event' refr={refresh} refresh={setRefresh}/>
                    <div className="imgshow">
                        {images && images.map((i) =>
                            i.type === "event" ?
                                <img src={i.image} alt="preview" style={{ height: '200px', width: 'auto' }} />
                                : <></>)}
                    </div>
                </div>
            </div>
            
            {/* Logos de SPONSORS */}
            <div className="sponsors">
                <h1> Logos de Sponsors </h1>
                <div className="imgdiv">
                    <ImageUploader eventId={eid} type="sponsor" refr={refresh} refresh={setRefresh}/>
                    <div className="imgshow">
                        {images && images.map((i) =>
                            i.type === "sponsor" ?
                                <img src={i.image} alt="preview" style={{ height: '150px', width: 'auto' }} />
                                : <></>)}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ConfiguradorContainer;