import React from 'react'
import Tournament from '../Tournament/Tournament'
import Grid from '@mui/material/Grid'


const TournamentList = ({ tourns }) => {

  return (
    <Grid container spacing={3}>
      { tourns.map((t) =>
        <Grid item xs={12} md={4}>
          <Tournament tournament={t} key={t.id} />
        </Grid>
       ) }
    </Grid>
  )
  
}

export default TournamentList