import React from 'react'
import "./Event.css"
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'



const Event = ({ tid, event }) => {

  return (

    <Box className='list-item'>
      <Container maxWidth="xs" className='card'>
        <Container maxWidth="lg" className='card-body'>
          <Grid container direction="column" spacing={1} justifyContent="space-evenly">
            <Grid item className='linea'>
                <h5 className="card-title"> {event.name} </h5>
            </Grid>
            <Grid item className='linea juego'>
              <p className="card-text"> {event.videogame.name} </p>
            </Grid>
            <Grid item>
              <p className="card-text"> Inscriptos: {event.numEntrants} </p>
            </Grid>
            <Grid item>
              <a href={`https://www.start.gg/${event.slug}`} target='_blank'> Ver en start.gg </a>
              <Link to={`/tournaments/${tid}/events/${event.id}`}>
                <a className="btn btn-primary"> Detalles </a>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  )
}

export default Event