import React, { useState, useEffect } from 'react';
import { apiURL } from "../../services/apiUrl";
import DeleteSetUpComponent from './DeleteSetup/DeleteSetUp.component';
import AddSetUpComponent from './AddSetup/AddSetUp.component';
import EditSetUpComponent from './EditSetup/EditSetUp.component';
import './SetUpList.css';
import tv from '../../assets/monitor_tv.png'

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, createTheme, Paper, alpha} from '@mui/material';
import { green, indigo, red, yellow } from '@mui/material/colors';


const SetUpListComponent = ({ eventID, activo, eventData, eventoAutomatico }) => {

    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false)
    const [adding, setAdding] = useState(false)
    const [modifying, setModifying] = useState(false)
    const [setups, setSetups] = useState([])

    const theme = createTheme({
        palette: {
            mode: 'dark'
          },
    })

    const styles = {
            backgroundColor: '#1A2027',
            ...theme.typography.body2,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
      };

    // recibe la lista de setups y abre el dialog
    const abrirDialog = async () => {
        setOpen(true)
    }

    const cerrarDialog = () => {
        setOpen(false)
    }

    
    useEffect(() => {
        if (open) {
        fetchSetUps(eventID)
        }
    }, [open, eventID]);

    useEffect(() => {
        if (!deleting){
            fetchSetUps(eventID)
        }
    }, [deleting, eventID])

    useEffect(() => {
        if (!adding){
           fetchSetUps(eventID) 
        }
    }, [adding, eventID])

    useEffect(() => {
        if (!modifying){
           fetchSetUps(eventID) 
        }
    }, [modifying, eventID])

    const fetchSetUps = async (eventID) => {
        try {
            const response = await fetch(`${apiURL}/api/estaciones/event/${eventID}`);
            const data = await response.json();
            setSetups(data)
        } catch (error) {
            console.log(error);
            return []
        }
    };

    function borrarSetUp(valor) {
        setDeleting(valor)
    }

    function agregarSetUp(valor) {
        setAdding(valor)
    }

    const modificarSetUp = (valor) => {
        setModifying(valor)
    }

    const getColor = (state) => {
        if (state === 'libre') {
            return green['A700']
        }
        else if (state === 'mantenimiento') {
            return red['A400']
        }
        else if (state === 'ocupado') {
            return yellow[900]
        } 
    } 

    const renderSetUps = () => {
        return (
            <Grid container spacing={3}>
                {setups.map((setup) => (
                    <Grid item xs={4}>
                        <Paper sx={[styles, {borderStyle:'solid', borderWidth: '0.2vw', borderColor: getColor(setup.state)}]}>
                            <div className='top'><img src={tv} height="65" width="82" alt="" /></div>
                            <div className='mid'>#{setup.numero}</div>
                            <div className='mid small'>-{setup.set}</div>
                            <div className='bot'><DeleteSetUpComponent setup={(setup)} sendData={borrarSetUp} />&nbsp;
                            <EditSetUpComponent setup={(setup)} setups={setups} eventData={eventData} sendData={modificarSetUp} eventoAutomatico={eventoAutomatico}/></div>
                        </Paper>
                    </Grid>
                ))}
            </Grid>          
        );
    };

    return (
            <div>
                <Button onClick={abrirDialog}>SetUps</Button>
                <Dialog
                    open={open}
                    onClose={cerrarDialog}
                    scroll={'paper'}
                    maxWidth={'md'}
                    fullWidth={true}
                    PaperProps={{
                        style: {
                            backgroundColor: alpha(indigo[900], 0.90)
                        }
                    }}
                >
                    <DialogTitle
                        id="scroll-dialog-title"
                        display={'flex'} 
                        justifyContent={'space-between'}
                    >SetUps {activo ? <AddSetUpComponent setups={setups} sendData={agregarSetUp} eventId={eventID}/> : <></> }</DialogTitle>
                    <DialogContent dividers={true}>
                        {renderSetUps()}
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={cerrarDialog}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </div>
    );
}

export default SetUpListComponent