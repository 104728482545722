import React, { useState, useEffect } from 'react';
import { apiURL } from "../../../services/apiUrl";
import Swal from 'sweetalert2';

// mui
import EditIcon from '@mui/icons-material/Edit';
import { Button,  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, InputLabel, FormControl} from '@mui/material';


const EditSetUpComponent = ({setup, sendData, eventData, eventoAutomatico}) => {

    const [open, setOpen] = useState(false);
    const [estado, setEstado] = useState(setup.state)


    const abrirDialog = () => {
        setOpen(true)
        setEstado(setup.state)
        sendData(true)
    }

    useEffect(() => {
    }, [open, eventoAutomatico])

    const cerrarDialog = async (modificar) => {
        if (modificar) {
            await modificarSetUp(setup._id)
            setOpen(false)
            sendData(false)
        }
        else {
            setOpen(false)
            sendData(false)
        }
    }

    const modificarSetUp = async (id) => {
        try {
            await fetch(`${apiURL}/api/estaciones/${id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    state: estado,
                    eventData: eventData,
                    automatico: eventoAutomatico
                })
            }).then((res) => res.json()).then((result) => {
                if (result.modificado !== 'ok') {
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al modificar SetUp',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        background: '#0f1926'
                    })
                }
            })
        }
        catch{
            Swal.fire({
                title: 'Error',
                text: 'Error al modificar SetUp',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                background: '#0f1926',
                color: '#fff'
            })
        }   
    }

    return (
        <>
            <IconButton  onClick={() => {abrirDialog();}}><EditIcon /></IconButton>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown'){
                        cerrarDialog(false)
                    }
                }}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    Editar SetUp #{setup.numero}
                </DialogTitle>
                <DialogContent dividers={true} sx={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <FormControl sx={{minWidth: '35%'}}>
                        <InputLabel id="estado-label">Estado</InputLabel>
                        <Select
                            labelId='estado-label'
                            label='Estado'
                            value={estado}
                            onChange={(e) => setEstado(e.target.value)}
                            sx={{minWidth: '30%'}}
                        >
                            <MenuItem value={'libre'}>Libre</MenuItem>
                            <MenuItem value={'mantenimiento'}>En mantenimiento</MenuItem>
                            <MenuItem value={'ocupado'}>Ocupado</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={() => {cerrarDialog(true)}}>Aceptar</Button>
                    <Button onClick={() => {cerrarDialog(false)}}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default EditSetUpComponent