import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAI01rdkFohEyEWv52-YFwTY-NjM0IUcUM",
  authDomain: "eventflowtournaments.firebaseapp.com",
  projectId: "eventflowtournaments",
  storageBucket: "eventflowtournaments.appspot.com",
  messagingSenderId: "404223686896",
  appId: "1:404223686896:web:8a6b0f260d5349653b2a35",
  measurementId: "G-4N4KBQZJCM"
};

export default firebaseConfig