import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { apiURL } from "../../services/apiUrl";
import { PlayCircle } from '@mui/icons-material';
import { IconButton, Grid, Box } from '@mui/material';
import Swal from 'sweetalert2';
import { userContext } from '../../context/UserProvider';

const SetsToStartComponent = ({iniciandoEvento, actualizarSets, eventoAutomatico, eventSlug, torneoSlug, resultEnviado, isOrg }) => {
    const [sets, setSets] = useState();
    const { eid } = useParams();
    const [setsLoading, setSetsLoading] = useState([])

    const { isAdmin } = useContext(userContext);

    const fetchSets = async (eid) => {
        try {
            const response = await fetch(`${apiURL}/api/event/${eid}/start`);
            const data = await response.json();
            setSets(data.sets.nodes.sort((a,b) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0)));
        } catch (error) {
            console.log(error);
        }
    };

    const fetchSetUps = async (eventID) => {
        try {
            const response = await fetch(`${apiURL}/api/estaciones/event/${eventID}`);
            const data = await response.json();
            return data
        } catch (error) {
            console.log(error);
            return []
        }
    };

    const startSet = async (set) => {
        const setups = await fetchSetUps(eid)
        let combo = ''
        setups.forEach(element => 
            {if (element.state === 'libre'){
                combo = combo + `<option value=${element._id}>Setup #${element.numero}</option>`
            }});
        Swal.fire({
            title: 'Iniciar partida',
            html: ( setups.find(setup => setup.state == 'libre') ?
            '<div class="div-setups">' +
                '<select id="res" style="width: 50%">' +
                    combo   +
                '</select>' +
            '</div>' : '<h5>No hay setups disponibles</h5>'),
            focusConfirm: false,
            confirmButtonText: 'Aceptar',
            preConfirm: () => {
                if (document.getElementById('res')){
                    return document.getElementById('res').value
                } else {
                    return null
                }

            }})
            .then(async (result) => {
                if (result.isConfirmed && result.value){
                    setSetsLoading([...setsLoading, set.id])
                    await fetch(`${apiURL}/bot/iniciar-partida`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'Accept': 'application/json'
                        },
                        body: JSON.stringify({
                            torneoSlug: torneoSlug,
                            eventoSlug: eventSlug,
                            setId: set.id,
                            eventoAutomatico: eventoAutomatico,
                            eventoId: eid,
                            setupId: result.value
                        })
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (data.result === 'ok') {
                            setTimeout(() => {
                                setSetsLoading((prevLoadingButtons) => prevLoadingButtons.filter((buttonId) => buttonId !== set.id))
                                fetchSets(eid)
                                resultEnviado()
                            }, 25000);
                        } else if (data.result === 'setup-error') {
                            alertaError("El setup no está libre o hubo un error con la partida")
                            setSetsLoading((prevLoadingButtons) => prevLoadingButtons.filter((buttonId) => buttonId !== set.id))
                        }
                    })
                }
            })
    };

    const alertaError = async (mensaje) => {
        Swal.fire(
            {
                position: 'top-end',
                icon: 'error',
                title: mensaje,
                showConfirmButton: false,
                backdrop: false,
                timer: 1500
              }
        )
    }

    function entrants(set) {
        return [set.slots[0].entrant.name, set.slots[1].entrant.name, "-", "-"]
    }

    function tbd(set) { // devuelve false si uno de los dos entrants es null
        if (set.slots[0].entrant === null || set.slots[1].entrant === null) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        fetchSets(eid);
    }, [eid, iniciandoEvento, actualizarSets]);

    return (
        <>
            <h5> Partidas a iniciar </h5>

            {sets ? sets.length !== 0 ?
                <Grid container spacing={4} className='partidas'>
                  {sets.map((set) => (
                        tbd(set) ?
                            <Grid item xs={12} md={4} sm={6}>
                                <Box className="partida">
                                    <Box className="participantes">
                                        <h6> {entrants(set)[0]} </h6>
                                        <h6> {entrants(set)[1]} </h6>
                                    </Box>
                                    <Box className="resultados">
                                        <h6> {entrants(set)[2]} </h6>
                                        <h6> {entrants(set)[3]} </h6>
                                    </Box>
                                    { !eventoAutomatico &&  (isAdmin || isOrg ) ?
                                    // Boton de iniciar partida
                                    <IconButton
                                    onClick={() => startSet(set)}
                                    disabled={setsLoading.includes(set.id)}
                                    className='pausar-match'
                                    size='small'
                                    color='primary'
                                    >
                                        <PlayCircle />
                                    </IconButton>
                                    : <></>}
                                </Box>
                            </Grid>
                            : <></>
                    ))}
                </Grid>
                : "No hay partidas para iniciar" : "Cargando partidas..."
            }

        </>
    );
};

export default SetsToStartComponent;