import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventDetail from "../../components/EventDetail/EventDetail";
import { apiURL } from "../../services/apiUrl";

const EventDetailContainer = () => {
    const [event, setEvent] = useState();
    const { tid, eid } = useParams();

    const eventById = async () => {
        const response = await fetch(`${apiURL}/api/tournaments/${tid}/events/${eid}`);
        const result = await response.json();
        setEvent(result)        
    }

    useEffect(() => {
        eventById();
    }, [eid])

    return (
        <>
            { event && < EventDetail event={event} /> }
        </>  
    )

}

export default EventDetailContainer;






