import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { apiURL } from "../../services/apiUrl";
import Swal from "sweetalert2";

import { LoadingButton } from '@mui/lab';
import { Upload, PauseCircle, Refresh } from '@mui/icons-material';
import { IconButton, Grid, Container, Box } from '@mui/material';
import { userContext } from '../../context/UserProvider';

const SetsInProgressComponent = ({ eventId, eventSlug, torneoSlug, resultEnviado, iniciandoEvento, eventoAutomatico, actualizarSets, isOrg }) => {

    const { userC, isPlayer, isAdmin } = useContext(userContext);

    const [sets, setSets] = useState();
    const [setsLoading, setSetsLoading] = useState([])
    const { eid } = useParams();

    const fetchSets = async (eid) => {
        try {
            const response = await fetch(`${apiURL}/api/event/${eid}/progress`);
            const data = await response.json();
            isPlayerMatch("p1", "p2")
            setSets(data.sets.nodes);
        } catch (error) {
            console.log(error);
        }
    };

    function obtenerIndiceGanador(slots, winnerId) {
        for (let i = 0; i < slots.length; i++) {
            if (slots[i].entrant.id === winnerId) {
                return i;
            }
        }
        return -1; // Si no se encuentra el ganador en la matriz
    }

    function invertir(index) {
        return index === 0 ? 1 : 0;
    }

    function resultado(set) {
        if (!set.slots[0].entrant) {
            return ["TBD", "TBD", "-", "-"]
        }
        if (!set.slots[1].entrant) {
            return [set.slots[0].entrant.name, "TBD", "-", "-"]
        }
        if (!set.displayScore) {
            return [set.slots[0].entrant.name, set.slots[1].entrant.name, "-", "-"]
        }
        if (set.displayScore === "DQ") {
            const winner = obtenerIndiceGanador(set.slots, set.winnerId)
            return ([
                set.slots[winner].entrant.name,
                set.slots[invertir(winner)].entrant.name,
                "W",
                "DQ"
            ])
        }
        return ([
            set.slots[1].entrant.name,
            set.slots[0].entrant.name,
            set.displayScore.split(" - ")[0],
            set.displayScore.split(" - ")[1]
        ])
    }

    useEffect(() => {
        fetchSets(eid);
    }, [eid, iniciandoEvento, actualizarSets]);

    const pauseSet = async (set) => {
        await Swal.fire({
            title: 'Pausar partida',
            text: '¿Seguro que desea pausar la partida?',
            focusConfirm: false,
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar',
            showDenyButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSetsLoading([...setsLoading, set.id])
                await fetch(`${apiURL}/bot/pausar-partida`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        torneoSlug: torneoSlug,
                        eventoSlug: eventSlug,
                        setId: set.id,
                        eventoAutomatico: eventoAutomatico,
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        if (data.result === 'ok') {
                            setTimeout(() => {
                                setSetsLoading((prevLoadingButtons) => prevLoadingButtons.filter((buttonId) => buttonId !== set.id))
                                fetchSets(eid)
                                resultEnviado()
                            }, 25000);
                        }
                    })
            }
        })
    }

    const addResult = async (set) => {
        await Swal.fire({
            title: 'Cargar resultados',
            html:
                '<div class="div-res">' +
                `<h4 mb-0> ${set.slots[0].entrant.name} </h4>` +
                '<select id="res-one" style="width: 6vw">' +
                '<option value="DQ">DQ</option>' +
                '<option value="0" selected>0</option>' +
                '<option value="1">1</option>' +
                '<option value="2">2</option>' +
                '<option value="3">3</option>' +
                '<option value="4">4</option>' +
                '</select>' +
                '</div>' +
                '<div class="div-res">' +
                `<h4 mb-0> ${set.slots[1].entrant.name} </h4>` +
                '<select id="res-two" style="width: 6vw">' +
                '<option value="DQ">DQ</option>' +
                '<option value="0" selected>0</option>' +
                '<option value="1">1</option>' +
                '<option value="2">2</option>' +
                '<option value="3">3</option>' +
                '<option value="4">4</option>' +
                '</select>' +
                '</div>',
            focusConfirm: false,
            confirmButtonText: 'Enviar',
            preConfirm: () => {
                return [
                    { id: set.slots[0].entrant.id,
                      score: document.getElementById('res-one').value},
                    { id: set.slots[1].entrant.id,
                      score: document.getElementById('res-two').value } ]
            }
        }).then(async (result) => {
            if (result.isConfirmed && result.value[0].score !== '' && result.value[1].score !== '') {
                if (result.value[0].score !== result.value[1].score) {
                    setSetsLoading([...setsLoading, set.id])
                    await fetch(`${apiURL}/bot/cargar-puntos`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'Accept': 'application/json'
                        },
                        body: JSON.stringify({
                            torneoSlug: torneoSlug,
                            eventoSlug: eventSlug,
                            setId: set.id,
                            resultados: result.value,
                            modificar: false,
                            eventoId: eventId,
                            eventoAutomatico: eventoAutomatico,
                        })
                    })
                        .then(res => res.json())
                        .then(data => {
                            if (data.result === 'ok') {
                                setTimeout(() => {
                                    setSetsLoading((prevLoadingButtons) => prevLoadingButtons.filter((buttonId) => buttonId !== set.id))
                                    fetchSets(eid)
                                    resultEnviado()
                                }, 25000);
                            }
                        })
                }
                else {
                    alertaError('No pueden cargarse resultados iguales')
                }
            }
        })
    }

    const alertaError = async (mensaje) => {
        Swal.fire(
            {
                position: 'top-end',
                icon: 'error',
                title: mensaje,
                showConfirmButton: false,
                backdrop: false,
                timer: 1500
            }
        )
    }

    const isPlayerMatch = (p1, p2) => {
        console.log(userC.user.nickname === p1,  userC.user.nickname === p2)
        if(userC.user.nickname === p1 || userC.user.nickname === p2){
            return true;
        } return false;
    }

    return (
        <>
        <Box className='row'>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <h5> Partidas en curso </h5>
                <IconButton
                onClick={() => {fetchSets(eid); resultEnviado()}}
                >
                    <Refresh />
                </IconButton>
            </div>
        </Box>
        {sets ? sets.length !== 0 ?
            <Grid container spacing={4} className='partidas'>
                {sets.map((set) => (
                    <Grid item xs={12} md={4} sm={6}>
                        <Container className='partida' maxWidth='xl' sx={{minHeight: 80}}>
                            <Box className='participantes'>
                                <h6> {resultado(set)[0]} </h6>
                                <h6> {resultado(set)[1]} </h6>
                            </Box>
                            <Box className='resultados'>
                                <h6> {resultado(set)[2]} </h6>
                                <h6> {resultado(set)[3]} </h6>
                            </Box>
                        { ( isAdmin || isOrg || isPlayerMatch(resultado(set)[0], resultado(set)[1]) ) && <LoadingButton
                            onClick={() => addResult(set)}
                            loading={setsLoading.includes(set.id)}
                            className='button'
                            > {!setsLoading.includes(set.id) ? <Upload></Upload> : ''}
                            
                        </LoadingButton> }

                            
                        { ( isAdmin || isOrg ) && <IconButton
                        onClick={() => pauseSet(set)}
                        disabled={setsLoading.includes(set.id)}
                        className='pausar-match'
                        size='small'
                        color='error'
                        >
                            <PauseCircle />
                        </IconButton> }

                        </Container>
                    </Grid>
                ))}
            </Grid>
            : "No hay partidas en curso" : "Cargando partidas..."
        }          
    </>
    );
};

export default SetsInProgressComponent;