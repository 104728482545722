import { apiURL } from "./apiUrl.js"

const enviarTokenNotificacion = async (token) => {
    const url = apiURL + '/api/auth/notifications/register'
    const jwt = JSON.parse(localStorage.getItem('user')).token
    await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json',
            Authorization: 'JWT ' + jwt
        },
        body: JSON.stringify({
            firebaseToken: token
        })
    })
}

const actualizarTokenNotificacion = async (tokenViejo, token) => {
    const url = apiURL + '/api/auth/notifications/update'
    const jwt = JSON.parse(localStorage.getItem('user')).token
    await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json',
            Authorization: 'JWT ' + jwt
        },
        body: JSON.stringify({
            firebaseToken: token,
            oldToken: tokenViejo
        })
    })
}

const eliminarTokenNotificacion = async (token) => {
    const url = apiURL + '/api/auth/notifications/delete'
    const jwt = JSON.parse(localStorage.getItem('user')).token
    await fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json',
            Authorization: 'JWT ' + jwt
        },
        body: JSON.stringify({
            firebaseToken: token,
        })
    })
}

export {enviarTokenNotificacion, actualizarTokenNotificacion, eliminarTokenNotificacion}