import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { apiURL } from "../../services/apiUrl";
import Swal from "sweetalert2";
import CreateIcon from '@mui/icons-material/Create';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import { userContext } from '../../context/UserProvider';

const PaginationComponent = ({ eventName, eventId, torneoSlug, isActive, iniciandoEvento, game, isOrg }) => {

    const { isPlayer, isAdmin } = useContext(userContext);
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { eid } = useParams();

    const [characters, setCharacters] = useState([]);

    const fetchUsers = async (page) => {
        try {
            const response = await fetch(`${apiURL}/api/event/${eid}/entrants?page=${page}`);
            const data = await response.json();
            setUsers(data.event.entrants.nodes);
            setTotalPages(data.event.entrants.pageInfo.totalPages);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!iniciandoEvento){
            fetchUsers(currentPage);
        }
    }, [currentPage, iniciandoEvento]);

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const renderUsers = () => {
        if (users.length === 0) {
            return <div>Cargando participantes...</div>;
        }

        return (
            <>
            <Grid container spacing={1}>
              {users.map((user) => (
                <Grid item xs={6} md={4}>
                    <Box className='nombres'>
                        <Grid container justifyContent='space-between' alignItems='center'>
                          <Grid item xs={12} md={9} alignItems='center'>
                            <p>{user.name}</p>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {!isPlayer && (isAdmin || isOrg) && 
                            <Button className='boton-nombres' onClick={selectChar} size='small'>
                                <CreateIcon></CreateIcon>
                            </Button> }
                          </Grid>
                        </Grid>
                    </Box>
                </Grid>
              ))}
            </Grid>
            </>
            
        );
    };

    const renderPagination = () => {
        if (totalPages === 0) {
            return null;
        }

        return (
            <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <li
                        key={page}
                        className={currentPage === page ? 'active' : ''}
                        onClick={() => goToPage(page)}
                    >
                        {page}
                    </li>
                ))}
            </ul>
        );
    };



    const addEntrant = async () => {
        await Swal.fire({
            title: 'Ingrese gamertag',
            input: 'text',
            inputPlaceholder: 'Ingrese gamertag',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            inputValidator: (value) => {
                if (!value) {
                    return 'Debe ingresar un gamertag';
                }
                if (value.length >= 26){
                    return 'Debe ingresar hasta 25 carácteres'
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed && result.value){
                await fetch(`${apiURL}/bot/agregar-player`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        name: result.value,
                        eventName: eventName,
                        torneoSlug: torneoSlug
                    })
                })
                    .then(res => res.json())
                    
            }
        });
    }

    const addEntrants = async () => {
        await Swal.fire({
            title: 'Añadir varios',
            html:
                '<textarea class="textarea-entrants" id="valueEntrants" name="valueEntrants">',
            focusConfirm: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Enviar',
            preConfirm: () => {
                return document.getElementById('valueEntrants').value
            }
        }).then(async (result) => {
            if (result.isConfirmed && result.value){
                await fetch(`${apiURL}/bot/agregar-player/bulk`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        names: result.value,
                        eventName: eventName,
                        torneoSlug: torneoSlug
                    })
                })
                    .then(res => res.json())
                    
            }
        });
    }

    const fetchCharacters = async () => {
        try {
            const response = await fetch(`${apiURL}/api/characters`);
            const data = await response.json();
            const chars = data.filter(objeto => objeto.game.name === game);
            setCharacters(chars);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCharacters();
    }, []);

    const charsHTML = () => {
        if(characters.length == 0){ 
            return '<option selected> Sin Personajes </option>';
        }
        const result = characters.map(char => `<option value="${char._id}">${char.name}</option>`).join('');
        return result;
    }

    const selectChar = async (e) => {
        const elemento = e.currentTarget.parentNode.parentNode.querySelector('p');
        const gamertag = elemento.textContent;
      
        await Swal.fire({
            title: 'Seleccionar personaje: ' + gamertag,
            html:
                "<select id='selectChar' name='select'>" +
                   charsHTML()
                + "</select>",
            focusConfirm: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Enviar',
            preConfirm: () => {
                const selectedOption = document.querySelector("#selectChar").value;
                return [gamertag, selectedOption];
            }
        })
        .then(async (result) => {
            if (result.isConfirmed && result.value){
                await fetch(`${apiURL}/api/playerchar`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        player: result.value[0],
                        char: result.value[1],
                        event: eventId
                    })
                })
                    .then(res => res.json())
            }
        });
    }
    

    return (
        <>
            <Container maxWidth="xl" className='title'>
                <h5> Participantes </h5>
                        {isActive ?
                            <>
                                { !isPlayer && (isAdmin || isOrg) && <Button className='add-entrant' size='small' onClick={addEntrant}> Añadir </Button> }
                                { !isPlayer && (isAdmin || isOrg) && <Button className='add-entrant' size='small' onClick={addEntrants}> Añadir varios </Button> }
                            </>

                            : <></>}
                        {/* gif de carga activado/desactivado */}
            </Container>
            <Container maxWidth="xl" disableGutters className='entrants-info'>
                {renderUsers()}
                {renderPagination()}
            </Container>
        </>
    );
};

export default PaginationComponent;