import React from 'react';
import { useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { apiURL } from '../../services/apiUrl';
import Swal from "sweetalert2";
import { userContext } from '../../context/UserProvider';
import "./Navbar.css";
import { eliminarTokenNotificacion } from '../../services/notifications.service';
import { deleteToken } from 'firebase/messaging';
import { messaging } from '../../firebase';
import { useNavigate } from 'react-router-dom';

// Material
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import { IconButton, Menu, MenuItem, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

const Navbar = () => {

    const [eventsHTML, setEventsHTML] = useState("");
    const [scrollBg, setScrollBg] = useState(false);
    const { userC, setUserC, isPlayer } = useContext(userContext);
    const ownerId = userC ? userC.user.sggId : 100;

    const [anchorElNav, setAnchorElNav] = useState(null);

    const navigate = useNavigate()

    const handleOpenNavMenu = (event) => {
        event.stopPropagation();
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = (event) => {
        event.stopPropagation();
        setAnchorElNav(null);
    };

    const fetchEvents = async () => {
        try {
            const response = await fetch(`${apiURL}/api/anunciador?ownerId=${ownerId}`);
            const data = await response.json();
            const tournaments = data.nodes;
            const allEvents = tournaments.reduce((acumulador, torneo) => {
                return acumulador.concat(torneo.events);
            }, []);

            const evHTML = allEvents.map(e => `<option value="${e.id}">${e.name}</option>`).join('');
            setEventsHTML(evHTML);

        } catch (error) {
            console.log(error);
        }
    };

    const handleScroll = () => {
        // Cambiar el fondo cuando se ha desplazado 100px hacia abajo
        if (window.scrollY > 30) {
            setScrollBg(true);
        } else {
            setScrollBg(false);
        }
    };

    useEffect(() => {
        fetchEvents();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [userC]);

    const selectEvent = async () => {
        if (eventsHTML === "") {
            await fetchEvents();
        }

        await Swal.fire({
            title: 'Seleccionar Evento',
            html: '<select id="opcionSelect" class="swal2-input" style="width: 20vw">' +
                eventsHTML
                + '</select>',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const selectedOption = document.getElementById('opcionSelect').value;
                return selectedOption;
            }
        }).then(result => {
            if (result.isConfirmed) {
                const selectedValue = result.value;
                // window.location.href = `/anunciador?eid=${selectedValue}`;
                window.open(`/anunciador?eid=${selectedValue}`, "_blank");
            }
        });
    }

    const configEvent = async () => {
        if (eventsHTML === "") {
            await fetchEvents();
        }

        await Swal.fire({
            title: 'Seleccionar Evento',
            html: '<select id="opcionSelect" class="swal2-input" style="width: 20vw">' +
                eventsHTML
                + '</select>',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const selectedOption = document.getElementById('opcionSelect').value;
                return selectedOption;
            }
        }).then(result => {
            if (result.isConfirmed) {
                const selectedValue = result.value;
                window.location.href = `/configurar-anunciador?eid=${selectedValue}`;
            }
        });
    }

    const [anchorEl, setAnchorEl] = useState(false);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        const confirmLogout = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Estás a punto de cerrar sesión.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar sesión',
            cancelButtonText: 'Cancelar',
        });

        if (confirmLogout.isConfirmed) {
            try {
                const response = await fetch(`${apiURL}/api/auth/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    console.log(response);
                    deleteToken(messaging)
                    const token = localStorage.getItem('notification_token')
                    if (token) {
                        await eliminarTokenNotificacion(token.token)
                    }
                    localStorage.removeItem('user');
                    localStorage.removeItem('notification_token')
                    setUserC(null);
                    navigate('/');
                    window.location.reload();
                } else {
                    console.error('Error al hacer logout');
                }
            } catch (error) {
                console.error('Error al hacer logout:', error);
            }
        }
    };

    return (
        <>
            <AppBar position="fixed"
                style={{
                    background: scrollBg ? '#04192a' : 'transparent',
                    borderBottom: scrollBg ? '2px solid #073c8a' : 'none',
                    boxShadow: 'none',
                    zIndex: 10,
                    transition: '0.2s all'
                }}
                className='navbar'>
                <Container maxWidth="xl">
                    <Toolbar className='menu' disableGutters>
                        <Link to={"/"} className='logo'> <img src="https://i.imgur.com/lSPkgJM.png" alt="logo" /> </Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "end" }} className='box-menu'>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>

                            {/* Vista Mobile */}
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                disableScrollLock={true}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: 'block', md: 'none' }, }}
                            >
                                {userC && userC.user.sggId && <div>
                                    <MenuItem className='menu-item' onClick={handleCloseNavMenu}>
                                        <Link className="list-item" to={`/tournaments?ownerId=${ownerId}`}> Mis Torneos  </Link>
                                    </MenuItem>
                                    <MenuItem className='menu-item' onClick={handleCloseNavMenu}>
                                        <Link className="list-item" onClick={selectEvent}> Anunciador  </Link>
                                    </MenuItem>
                                    <MenuItem className='menu-item' onClick={handleCloseNavMenu}>
                                        <Link className="list-item" onClick={configEvent}> Configurador </Link>
                                    </MenuItem>
                                </div>
                                }
                                {userC && isPlayer &&
                                    <MenuItem className='menu-item' onClick={handleCloseNavMenu}>
                                        <Link className="list-item" to={`/tournaments`}> Mis Torneos  </Link>
                                    </MenuItem>
                                }
                                <MenuItem className='menu-item' onClick={handleCloseNavMenu}>
                                    {userC ?
                                        <Link className="list-item" onClick={handleLogout}> Logout </Link> :
                                        <Link className="list-item login" to={"/login"}> Iniciar Sesión </Link>
                                    }
                                </MenuItem>
                            </Menu>
                        </Box>

                        {/* Vista Escritorio */}
                        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }} className='list'>
                            {userC && userC.user.sggId && <>
                                <Link className="list-item" to={`/tournaments?ownerId=${ownerId}`}> Torneos  </Link>
                                <Link className="list-item" onClick={selectEvent}> Anunciador  </Link> 
                                <Link className="list-item" onClick={configEvent}> Configurador </Link> 
                            </>}
                            {userC && isPlayer &&
                                <Link className="list-item" to={`/tournaments`}> Torneos  </Link>
                            }
                            {userC ?
                                <>
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar-desk"
                                        aria-haspopup="true"
                                        onClick={handleProfileMenuOpen}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    {/* Menu */}
                                    <Menu
                                        id="menu-appbar-desk"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem> {`User: ${userC.user.nickname}`} </MenuItem>
                                        <MenuItem> Perfil </MenuItem>
                                        <MenuItem onClick={handleLogout}> Logout </MenuItem>
                                    </Menu>
                                </>
                                : <Link className="list-item login" to={"/login"}> Iniciar Sesión </Link>
                            }
                        </Box>
                    </Toolbar>

                </Container>

            </AppBar>

        </>
    )
}

export default Navbar