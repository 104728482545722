import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import TournamentList from '../../components/TournamentList/TournamentList';
import "./TournamentListContainer.css";
import { apiURL } from "../../services/apiUrl";
import Container from '@mui/material/Container'

const TournamentListContainer = () => {
    const [tournaments, setTournaments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const ownerId = query.get("ownerId");

    const fetchTournaments = async (page) => { 
        try {
            const response = await fetch(`${apiURL}/api/tournaments?ownerId=${ownerId}&page=${page}`);
            const data = await response.json();
            setTournaments(data.nodes);
            setTotalPages(data.pageInfo.totalPages);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        document.title = `EventFlow | Mis Torneos`;
    }, [])
    
    useEffect(() => {
        fetchTournaments(currentPage);
    }, [currentPage]);

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const renderTournaments = () => {
        if (tournaments.length === 0) {
            return <Container className='waiting'> Cargando torneos... </Container>;
        }

        return (
            <div className='tournament-list'>
                { tournaments && <TournamentList tourns={tournaments} /> }
            </div>
        );
    };

    const renderPagination = () => {
        if (totalPages === 0) {
            return null;
        }

        return (
            <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <li
                        key={page}
                        className={currentPage === page ? 'active' : ''}
                        onClick={() => goToPage(page)}
                    >
                        {page}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <>
            <Container className='tournament-info'>
                <h1> Torneos </h1>
                { renderTournaments() }
                { renderPagination() }
            </Container>
        </>
    );





}

export default TournamentListContainer