import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import "./EventDetail.css";
import EntrantsPagination from "../EntrantsPagination/EntrantsPagination";
import SetsPagination from "../SetsPagination/SetsPaginations";
import SetsInProgress from "../SetsInProgress/SetsInProgress";
import SetsToStart from "../SetsToStart/SetsToStart";
import { apiURL } from "../../services/apiUrl";
import SetUpListComponent from "../SetUps/SetUpList";
import Swal from "sweetalert2";
import { LoadingButton } from '@mui/lab';
import { userContext } from '../../context/UserProvider';
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import { FormControlLabel, Box, Grid } from "@mui/material";
import Button from '@mui/material/Button';



const EventDetail = ({ event }) => {

    const { userC, isPlayer, isAdmin, isOrganizer } = useContext(userContext);
    const isOrg = isOrganizer(event.tournament.owner.id)

    function getSlug() {
        const completeSlug = event.slug;
        const arraySlug = completeSlug.split("/")
        return [arraySlug[1], arraySlug[3]]
    }

    const [torneo, evento] = getSlug(event.slug);
    const [iniciado, setIniciado] = useState(false);
    const [resultEnviado, setResultEnviado] = useState(false);
    const [autoEvent, setAutoEvent] = useState(true)

    const navigate = useNavigate();

    function isActive(state) {
        if (state === "ACTIVE" || state === "COMPLETED" ) { return false }
        return true;
    }

    useEffect(() => {
        getAutomatizacion()
    }, [""])

    const getAutomatizacion = async () => {
        let auto = true

        await fetch(`${apiURL}/api/automatizar/${event.id}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(res => res.json())
            .then(data => {auto = data.automatico})
        setAutoEvent(auto)
        return auto
    }

    const iniciarEvento = async () => {
        setIniciado(true)
        await fetch(`${apiURL}/bot/iniciar-evento`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                torneoSlug: torneo,
                eventoSlug: evento,
                eventoId: event.id,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.result === 'sin-estaciones') {
                    Swal.fire({
                        title: 'Error',
                        text: 'No se puede iniciar el evento sin SetUps',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        background: '#0f1926',
                        color: '#fff'
                    })
                    setIniciado(false)
                } else if (data.result === 'ok'){
                    const espera = setTimeout(() => {
                        event.state = 'ACTIVE'
                        setIniciado(false)
                    }, 100000)
                }
            })
    }

    const enviarResult = () => {
        setResultEnviado((previo) => !previo)
    }

    const setterEvento = async () => {
        const result = await cambiarAutomatizacion(!autoEvent)
        if (result === 'ok'){
        setAutoEvent(!autoEvent)
        }

    }

    const handleStartClick = () => {
        window.open(`https://www.start.gg/${event.slug}`, '_blank');
    };

    const handleReportClick = () => {
        window.open(`/report/${event.id}`, '_blank');
    };

    const cambiarAutomatizacion = async  (valor) => {
        const jwt = JSON.parse(localStorage.getItem('user')).token
        let result
        await fetch(`${apiURL}/api/automatizar`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: 'JWT ' + jwt
            },
            body: JSON.stringify({
                evento: {
                    id: event.id,
                    automatico: valor
                }
            })
        })
            .then(res => res.json())
            .then(data => result = data.result)
        return result
    }

    return (
        <>
            {<>
                <Box className="event-detail">
                    <Grid container spacing={4}>
                        <Grid item className="info" xs={12} md={2.4}>
                            <h1> Evento </h1>
                            <div className="imagen">
                                <img src={event.videogame.images[0].url} alt="juego" />
                            </div>
                            {
                                (isAdmin || isOrg) &&
                                <FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch checked={autoEvent} onChange={setterEvento} />
                                            }
                                            label="Automatizar evento"
                                            labelPlacement="end"
                                        >
                                        </FormControlLabel>
                                    </FormGroup>
                                </FormControl>
                            }

                            <Box className="event-info">
                                <h3> {event.name} </h3>
                                <h5> {event.videogame.name} </h5>
                                <h5> Inscriptos: {event.numEntrants} </h5>
                                {/* <a href={`https://www.start.gg/${event.slug}`} target='_blank'> Ver en start.gg </a> */}
                                <div>
                                    {isActive(event.state) ?
                                        <>
                                            <div>
                                                <Button href={`https://www.start.gg/admin/tournament/${torneo}/seeding/${event.id}`} target="_blank"> Ir a Seeding ↗ </Button>
                                            </div>
                                            {!isPlayer && (isAdmin || isOrg) && <LoadingButton
                                                onClick={iniciarEvento}
                                                className="Button"
                                                loading={iniciado}
                                                loadingPosition="end"
                                            >
                                                <div>Iniciar Evento</div>
                                            </LoadingButton>}
                                        </>
                                        : <></>}
                                </div>
                                <div>
                                    {!isPlayer && (isAdmin || isOrg) &&
                                        <>
                                            <SetUpListComponent
                                                eventID={event.id}
                                                activo={isActive(event.state)}
                                                eventData={{ torneoSlug: torneo, eventoSlug: evento }}
                                                eventoAutomatico={autoEvent}>
                                            </SetUpListComponent>
                                            {!isActive(event.state) && <Button onClick={handleReportClick}>Reportes ↗ </Button>}
                                        </>
                                    }
                                </div>
                                <Button onClick={handleStartClick} > START.GG ↗ </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9.6}>
                            <Box className="detalle">
                                <Box className="entrants">
                                    <EntrantsPagination
                                        eventId={event.id}
                                        eventName={event.name}
                                        torneoSlug={torneo}
                                        isActive={isActive(event.state)}
                                        iniciandoEvento={iniciado}
                                        game={event.videogame.name}
                                        isOrg={isOrg} />
                                </Box>

                                <Box className="iniciar">
                                    <SetsToStart
                                        eventId={event.id}
                                        iniciandoEvento={iniciado}
                                        actualizarSets={resultEnviado}
                                        eventoAutomatico={autoEvent}
                                        eventSlug={evento}
                                        torneoSlug={torneo}
                                        resultEnviado={enviarResult}
                                        isOrg={isOrg} />
                                </Box>

                                <Box className="en-curso">
                                    <SetsInProgress
                                        eventId={event.id}
                                        eventSlug={evento}
                                        torneoSlug={torneo}
                                        iniciandoEvento={iniciado}
                                        resultEnviado={enviarResult}
                                        eventoAutomatico={autoEvent}
                                        actualizarSets={resultEnviado}
                                        isOrg={isOrg} />
                                </Box>

                                <Box className="fases-partidas">
                                    <SetsPagination
                                        phases={event.phases}
                                        iniciandoEvento={iniciado}
                                        actualizarSets={resultEnviado}
                                        eventoAutomatico={autoEvent} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            }
        </>
    )

}

export default EventDetail;