import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiURL } from "../../services/apiUrl";
import Carousel from "react-bootstrap/Carousel";
import vslogo from "../../assets/vslogo.png";
import "./AnunciadorContainer.css"
import bgimage from '../../assets/1600.jpg';

const AnunciadorContainer = () => {

    const [event, setEvent] = useState("");
    const [recientes, setRecientes] = useState([]);
    const [sponsors, setSponsors] = useState([])
    const [orgImg, setOrgImg] = useState();
    const [eventImg, setEventImg] = useState();
    const [enCurso, setEnCurso] = useState([]);
    const [setups, setSetups] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const [game, setGame] = useState();

    const [allPlayers, setAllPlayers] = useState([]);
    const [matchups, setMatchups] = useState([]);
    const [stats, setStats] = useState([]);

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const eid = query.get("eid");

    const getEventById = async (eid) => {
        const response = await fetch(`${apiURL}/api/event/${eid}/progress`);
        const result = await response.json();
        const partidas = result.sets.nodes;
        setGame(result.videogame.name)
        setEvent(result);
        setEnCurso(partidas); //
    }

    const getAIniciar = async (eid) => {
        try {
            const response = await fetch(`${apiURL}/api/event/${eid}/start`);
            const data = await response.json();
            setRecientes(data.sets.nodes);
        } catch (error) {
            console.log(error);
        }
    }

    const getSponsors = async (eid) => {
        try {
            const response = await fetch(`${apiURL}/api/images/event/${eid}`);
            const data = await response.json();
            setSponsors(data);
        } catch (error) {
            console.log(error);
        }
    }

    const getOrgImg = () => {
        if (sponsors) {
            const imgorg = sponsors.find((i) => i.type === "organization");
            if (imgorg) {
                setOrgImg(imgorg.image)
            }
        }
    }

    const getEventImg = () => {
        if (sponsors) {
            const imgevent = sponsors.find((i) => i.type === "event");
            if (imgevent) {
                setEventImg(imgevent.image)
            }
        }
    }

    const getSetups = async (eid) => {
        const response = await fetch(`${apiURL}/api/estaciones/event/${eid}`);
        const result = await response.json();
        setSetups(result);
    }

    const isAssociated = (id) => {
        for (let i = 0; i < setups.length; i++) {
            if (id === setups[i].set) {
                return true;
            }
        }
        return false;
    }

    const nroSetup = (sid) => {
        const encontrado = setups.find(s => s.set === sid);
        if (encontrado) { return encontrado.numero; }
        else { return "X"; }
    }

    function diff(now, startedAt) {
        return now - startedAt
    }

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(secs).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    function Contador({ initialTime }) {
        const [time, setTime] = useState(initialTime);

        useEffect(() => {
            const interval = setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, 1000);
            return () => clearInterval(interval);
        }, []);

        return (
            <div className="timer"> <p>{formatTime(time)}</p> </div>
        );
    }

    useEffect(() => {
        getEventById(eid)
        getSetups(eid)
        getAIniciar(eid)
        getAllPlayers()
        getMatchups()
        getStats()
        document.querySelector(".navbar").style.display = "none";
        document.body.style.backgroundImage = `url(${bgimage})`;
    }, [refreshCount])

    useEffect(() => {
        getOrgImg()
        getEventImg()
    }, [sponsors])

    useEffect(() => {
        getSponsors(eid)
    }, [eid])

    useEffect(() => {
        document.title = `EventFlow | Anunciador ~ ${event.name}`;
    }, [event])
    

    // AUTO REFRESH
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshCount(refreshCount + 1);
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, [refreshCount]);

    const getAllPlayers = async () => {
        const response = await fetch(`${apiURL}/api/playerchar`);
        const result = await response.json();
        setAllPlayers(result);
    }

    const getMatchups = async () => {
        const response = await fetch(`${apiURL}/api/matchups`);
        const result = await response.json();
        setMatchups(result);
    }

    const getStats = async () => {
        const response = await fetch(`${apiURL}/api/stats`);
        const result = await response.json();
        setStats(result);
    }

    const getImg = (player) => {
        const encontrado = allPlayers.find((p) => (
            p.player === player &&
            p.event === event.id ));
        if (encontrado) {
            return encontrado.char.img;
        } else {
            return "https://i.imgur.com/NTXYLCp.png";
        }
    }

    const getCharacter = (player) => {
        const encontrado = allPlayers.find((p) => p.player === player & p.event == eid);
        if (encontrado) {
            return encontrado.char.name;
        } else {
            return "No hay personaje";
        }
    }

    const getMatchup = (char1, char2) => {
        const encontrado = matchups.find((m) =>
            m.nombrePersonajeFila === char1 &&
            m.nombrePersonajeColumna === char2);

        if (encontrado) {
            return encontrado.matchup_value;
        } else {
            return 50;
        }
    }

    const getVitality = (char) => {
        const encontrado = stats.find((p) =>
            p.character.name === char
        )
        console.log(encontrado)
        if (encontrado) {
            return encontrado.vitality;
        } else {
            return 0;
        }
    }

    const getWinrate = (char) => {
        const encontrado = stats.find((p) =>
            p.character.name === char
        )

        if (encontrado) {
            return encontrado.winrate;
        } else {
            return 0;
        }
    }

    const getPickrate = (char) => {
        const encontrado = stats.find((p) =>
            p.character.name === char
        )

        if (encontrado) {
            return encontrado.pickrate;
        } else {
            return 0;
        }
    }

    const getBanrate = (char) => {
        const encontrado = stats.find((p) =>
            p.character.name === char
        )

        if (encontrado) {
            return encontrado.banrate;
        } else {
            return 0;
        }
    }

    function tbd(set) {
        if (set.slots[0].entrant === null || set.slots[1].entrant === null) {
            return false;
        }
        return true;
    }

    function statsp(playerone, playertwo) {

        let elemento;
        switch (game) {
            case 'Street Fighter 6': // SFVI
                elemento =
                    <>
                        <p> Matchup {getMatchup(getCharacter(playerone), getCharacter(playertwo))} % </p>
                        <p> Vitality {getVitality(getCharacter(playerone))} </p>
                    </>
                break;
            case 'Guilty Gear: Strive': // SFVI
                elemento =
                    <>
                        <p> Matchup {getMatchup(getCharacter(playerone), getCharacter(playertwo))} % </p>
                    </>
                break;
            case 'VALORANT': // Valorant
                elemento =
                    <>
                        <p> Winrate {getWinrate(getCharacter(playerone))} % </p>
                        <p> Pickrate {getPickrate(getCharacter(playerone))} % </p>
                    </>
                break;
            case 'League of Legends': // LoL
                elemento =
                    <>
                        <p> Winrate {getWinrate(getCharacter(playerone))} % </p>
                        <p> Pickrate {getPickrate(getCharacter(playerone))} % </p>
                        <p> Banrate {getBanrate(getCharacter(playerone))} % </p>
                    </>
                break;
            default: // Default
                elemento = <></>;
                break;
        }
        return elemento;
    }

    return (
        <>
            <div className="anunciador">

                <button className="volver" onClick={() => { window.close() }}> <a href="/"> <img src="https://cdn-icons-png.flaticon.com/512/860/860774.png" alt="" /> </a> </button>
                <h1>{event.name}</h1>

                <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "5px", position: "fixed", top: "10px", right: "20px" }}>
                    {orgImg && <img style={{ width: "auto", height: "80px" }} src={orgImg} alt="logo" />}
                    {orgImg && <p style={{ margin: "0" }}> X </p>}
                    <img style={{ width: "70px", height: "44.8px" }} src="https://i.imgur.com/lSPkgJM.png" alt="logo" />
                </div>





                {/* ANUNCIADOR DE TODAS LAS PARTIDAS */}
                {enCurso.length > 0 ?
                    <div className="anunciador-partidas">
                        <Carousel interval={7000} controls={false} indicators={false} pause={false}>
                            {enCurso.map((p) => (
                                isAssociated(p.id) ? 
                                <Carousel.Item key={p.id}>
                                    <div className="info">
                                        {eventImg && <img src={eventImg} alt="Event Logo" style={{ height: '300px', width: 'auto', filter: "drop-shadow(0px 0px 5px rgb(0, 0, 0))" }} />}

                                        <div className="partida">
                                            <div className="player izq">
                                                <div className="character">
                                                    <img 
                                                        className={game === 'League of Legends' ? 'lol' : game === 'VALORANT' ? 'valo' : 'otros'}
                                                        src={getImg(p.slots[0].entrant.name)} alt="" />
                                                </div>
                                                <div className="stats">
                                                    <h2>{p.slots[0].entrant.name}</h2>
                                                    {statsp(p.slots[0].entrant.name, p.slots[1].entrant.name)}
                                                </div>
                                            </div>
                                            <div className="vs">
                                                <img src={vslogo} alt="" />
                                            </div>
                                            <div className="player der">
                                                <div className="stats">
                                                    <h2>{p.slots[1].entrant.name}</h2>
                                                    {statsp(p.slots[1].entrant.name, p.slots[0].entrant.name)}
                                                </div>
                                                <div className="character">
                                                    <img
                                                        className={game === 'League of Legends' ? 'lol' : game === 'VALORANT' ? 'valo' : 'otros'}
                                                        src={getImg(p.slots[1].entrant.name)} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <h1> ESTACIÓN N° 
                                            {nroSetup(p.id)}
                                        </h1>
                                        {<Contador initialTime={diff(Math.floor(Date.now() / 1000), p.startedAt)}></Contador>}
                                    </div>
                                </Carousel.Item>
                                : ""
                            ))}
                        </Carousel>
                    </div>
                    : <div className="sin-partidas"> <h1>ESPERANDO PARTIDAS...</h1> </div>}


                {/* ANUNCIADOR DE PARTIDAS RECIENTES */}
                <div className="anunciador-main">

                    {recientes.length === 0 ? <h2 style={{ border: "none", margin: 0 }}> No hay partidas a comenzar </h2> : <h2> Partidas a iniciar </h2>}

                    <div className="logos borderr" style={{ display: recientes.length === 0 ? "none" : "flex" }}>
                        <div className="logos-slide">
                            {recientes.map((partida) => (
                                tbd(partida) ?
                                    <p key={partida.id}>
                                        {partida.slots[0].entrant.name} VS {partida.slots[1].entrant.name}
                                    </p>
                                    : <></>
                            ))}
                        </div>
                        <div className="logos-slide">
                            {recientes.map((partida) => (
                                tbd(partida) ?
                                    <p key={partida.id}>
                                        {partida.slots[0].entrant.name} VS {partida.slots[1].entrant.name}
                                    </p>
                                    : <></>
                            ))}
                        </div>
                    </div>


                    <div className="logos" style={{ display: sponsors.length === 0 ? "none" : "flex" }}>
                        <div className="logos-slide">
                            {sponsors && sponsors.map((i) =>
                                i.type === "sponsor" ?
                                    <img src={i.image} alt="preview" style={{ height: '70px', width: 'auto' }} />
                                    : <></>)}
                        </div>
                        <div className="logos-slide">
                            {sponsors && sponsors.map((i) =>
                                i.type === "sponsor" ?
                                    <img src={i.image} alt="preview" style={{ height: '70px', width: 'auto' }} />
                                    : <></>)}
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default AnunciadorContainer;
