import React from 'react'
import Event from '../Event/Event'
import Grid from '@mui/material/Grid'

const EventList = ({ data }) => {

  return (
    <Grid container spacing={3}>
      { data && data.events && data.events.map((event) => 
        <Grid item xs={12} md={3}>
          <Event tid={data.id} event={event} key={event.id} />
        </Grid>
      ) }
    </Grid>
  )
  
}

export default EventList