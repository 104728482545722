import React from 'react'
import "./Tournament.css"
import { Link } from 'react-router-dom';
import {Container, Box} from '@mui/material'

const Tournament = ({ tournament }) => {

  return (
    <Container className='tournament-list-item'>
      
      <Box className="tournament-card">

        <Box className='imagen'>
          <img src={ tournament.images.length === 0 ? null :  tournament.images[0].url }/>
        </Box>

        <Box className="body" >
          <h6 className="title"> {tournament.name} </h6>
          
          <Link to={`/tournaments/${tournament.id}/events`}>
            <button className="btn btn-primary"> Ver Eventos </button>
          </Link>
        </Box>

      </Box>

    </Container>
  )
}

export default Tournament