import { React, useState, useContext, useRef } from 'react';
import "./Register.css";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import { InputLabel } from '@mui/material';
import { apiURL } from "../../services/apiUrl";
import { userContext } from '../../context/UserProvider';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken, onMessage } from "firebase/messaging";
import {messaging, app} from '../../firebase';
import { enviarTokenNotificacion, actualizarTokenNotificacion } from '../../services/notifications.service';

const Register = () => {

    const [validoNickname, setValidoNickname] = useState(true);
    const [validoUser, setValidoUser] = useState(true);
    const [validoSggDisc, setValidoSggDisc] = useState(true);
    const [validoPass, setValidoPass] = useState(true);
    const [validoConfirmPass, setValidoConfirmPass] = useState(true);
    const [validoRole, setValidoRole] = useState(true);

    const [error, setError] = useState(null);

    const nickname = useRef('')
    const user = useRef('')
    const sggDisc = useRef('')
    const pass = useRef('')
    const confirmPass = useRef('')
    const [role, setRole] = useState('')

    const { userC, setUserC } = useContext(userContext);

    const navigate = useNavigate();

    const validateNickname = (nickname) => {
        return nickname.length <= 15;
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validatePassword = (password) => {
        return password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);
    };

    // const validateSggDisc = (sggdisc) => {
    //     return 
    // };

    const validar = () => {
        return (
            (   nickname.current.value && 
                user.current.value &&
                // sggDisc.current.value &&
                pass.current.value && 
                confirmPass.current.value && 
                role ) 
            &&
            (   validateNickname(nickname.current.value) &&
                validateEmail(user.current.value) &&
                // validateSggDisc(sggDisc.current.value) &&
                validatePassword(pass.current.value) &&
                validatePassword(confirmPass.current.value) &&
                pass.current.value === confirmPass.current.value )
        )
    }

    const register = () => {
        if (!nickname.current.value || !validateNickname(nickname.current.value)) {
            setValidoNickname(false)
        } else {
            setValidoNickname(true)
        }
        if (!user.current.value || !validateEmail(user.current.value)) {
            setValidoUser(false)
        } else {
            setValidoUser(true)
        }
        // if (!sggDisc.current.value
        //     // || !validateSggDisc(sggDisc.current.value)
        //     ) {
        //     setValidoSggDisc(false)
        // } else {
        //     setValidoSggDisc(true)
        // }
        if (!pass.current.value || !validatePassword(pass.current.value)) {
            setValidoPass(false)
        } else {
            setValidoPass(true)
        }
        if (!confirmPass.current.value || !validatePassword(confirmPass.current.value) || !(pass.current.value === confirmPass.current.value)) {
            setValidoConfirmPass(false)
        } else {
            setValidoConfirmPass(true)
        }
        if (!role) {
            setValidoRole(false)
        } else {
            setValidoRole(true)
        }

        if (validar()) {
            const url = `${apiURL}/api/auth/signup`;
            const data = {
                nickname: nickname.current.value,
                email: user.current.value,
                sggDisc: sggDisc.current.value,
                password: pass.current.value,
                role: role 
            };
            console.log(data)

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la solicitud');
                    }
                    return response.json();
                })
                .then(data => {
                    setUserC(data);
                    localStorage.setItem('user', JSON.stringify(data));
                    navigate("/");
                })
                .then(response => {
                    if (!response.ok) {
                      throw new Error('Error en la solicitud');
                    }
                    return response.json();
                  })
                  .then(data => {
                    setUserC(data);
                    console.log(data)
                    localStorage.setItem('user', JSON.stringify(data));
                    navigate("/");
                  })
                  .then(async () => {
                    const msg = messaging;
                    const swSubs = await navigator.serviceWorker.register('./firebase-messaging-sw.js', {scope: 'firebase-cloud-messaging-push-scope'})
                    const token = await getToken(msg, {vapidKey: 'BJ_jtR4FyWAfl9OhpS23j8NFuB7Ys0aj1I0YAnhRab1V0J2OUiyaps-N7PMkpdhaDjYNDRxkV7I-SlNgB9jzknU', serviceWorkerRegistration: swSubs})
                    const tokenViejo = localStorage.getItem('notification_token')
                    if (!tokenViejo) {
                      localStorage.setItem('notification_token', JSON.stringify({token: token}))
                      await enviarTokenNotificacion(token)
                    } else if (tokenViejo.token !== token) {
                      localStorage.setItem('notification_token', JSON.stringify({token: token}))
                      await actualizarTokenNotificacion(tokenViejo.token, token)
                    }
                    onMessage(msg, (payload) => {
                        if (payload.data && payload.data.title){
                            Swal.fire(
                                {
                                    position: 'top',
                                    icon: 'info',
                                    title: payload.data.title,
                                    text: payload.data.body,
                                    showConfirmButton: false,
                                    backdrop: false,
                                    timer: 3000
                                  }
                            )
                        } else {
                            Swal.fire(
                                {
                                    position: 'top',
                                    icon: 'info',
                                    title: payload.notification.title,
                                    text: payload.notification.body,
                                    showConfirmButton: false,
                                    backdrop: false,
                                    timer: 3000
                                  }
                            )
                        }
                      })
                  })
                .catch(error => {
                    console.error(error);
                    setError('Error al registrar. Inténtelo de nuevo.');
                });
        }
    };

    return (
        <Container maxWidth="sm" className='register-container'>
            <Grid container columnSpacing={1} rowSpacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                textAlign="center"
                wrap="wrap">

                <Grid item xs={12}>
                    <h5>Registrarse</h5>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        error={!validoNickname}
                        id="nickname"
                        label="Nickname"
                        className='register-form'
                        helperText={validoNickname ? null : 'Debe ingresar un nickname válido (máximo 18 caracteres)'}
                        focused={!validoNickname}
                        inputRef={nickname}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={!validoUser}
                        id="username"
                        label="Email"
                        className='register-form'
                        helperText={validoUser ? null : 'Debe ingresar un email válido'}
                        focused={!validoUser}
                        inputRef={user}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={!validoSggDisc}
                        id="sggDisc"
                        label="Código start.gg (opcional)"
                        className='register-form'
                        helperText={validoSggDisc ? null : 'Debe ingresar un código de start.gg'}
                        focused={!validoSggDisc}
                        inputRef={sggDisc}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={!validoPass}
                        id="password"
                        label="Contraseña"
                        type='password'
                        className='register-form'
                        helperText={validoPass ? null : 'Debe ingresar una contraseña válida (mínimo 8 caracteres)'}
                        focused={!validoPass}
                        inputRef={pass}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={!validoConfirmPass}
                        id="confirmPassword"
                        label="Confirmar Contraseña"
                        type='password'
                        className='register-form'
                        helperText={validoConfirmPass ? null : 'Las contraseñas no coinciden o no son válidas'}
                        focused={!validoConfirmPass}
                        inputRef={confirmPass}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select item xs={12}
                        error={!validoRole}
                        id="role"
                        label="Role"
                        className='register-form'
                        displayEmpty
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <MenuItem value="" disabled selected hidden> Seleccionar rol...</MenuItem>
                        <MenuItem value="player">Player</MenuItem>
                        <MenuItem value="organizer">Organizer</MenuItem>
                    </Select>
                    <Grid item xs={12}>
                        {!validoRole && (
                            <Typography variant="caption" color="error">
                                Por favor, selecciona un rol.
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                {error && (
                    <Grid item xs={12}>
                        <p style={{ textAlign: "center", color: "red" }}>{error}</p>
                    </Grid>
                )}

                <Grid item xs={4} >
                    <Button variant="outlined" color="secondary" href='/login'>
                        Ir a Iniciar sesión
                    </Button>
                </Grid>

                <Grid item xs={4} >
                    <Button variant="outlined" color="primary" href='/'>
                        Volver
                    </Button>
                </Grid>

                <Grid item xs={4} >
                    <Button variant="contained" color="primary" onClick={register}>
                        Registrarse
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Register;


