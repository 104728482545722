import './App.css';

// imports
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// componentes
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Login from './pages/LogIn/LogIn';
import Register from './pages/Register/Register';
import TournamentListContainer from './pages/TournamentListContainer/TournamentListContainer';
import EventListContainer from './pages/EventListContainer/EventListContainer';
import EventDetailContainer from './pages/EventDetailContainer/EventDetailContainer';
import AnunciadorContainer from './pages/AnunciadorContainer/AnunciadorContainer';
import ConfiguradorContainer from './pages/ConfiguradorContainer/Configurador';
import ReportContainer from './pages/ReportContainer/ReportContainer';

// context
import UserProvider from './context/UserProvider';

const theme = createTheme({
  palette: {
    mode: 'dark'
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <UserProvider>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/failure-login" element={<Home />} />
              <Route path="/tournaments" element={<TournamentListContainer />} />
              <Route path="/tournaments/:tid/events" element={<EventListContainer />} />
              <Route path="/tournaments/:tid/events/:eid" element={<EventDetailContainer />} />
              {/* <Route path="/overlays" element={<OverlayContainer />} /> */}
              <Route path="/anunciador" element={<AnunciadorContainer />} />
              <Route path="/configurar-anunciador" element={<ConfiguradorContainer />} />
              <Route path="/report/:eid" element={<ReportContainer />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </>
    </ThemeProvider>
  );
}

export default App;
