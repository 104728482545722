import { createContext, useState } from "react";
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'

export const userContext = createContext();

const UserProvider = ({ children }) => {

    const [userC, setUserC] = useState(JSON.parse(localStorage.getItem('user')));
    const [userDecoded, setUserDecoded] = useState();

    // let userDecoded = userC ? jwtDecode(userC.token) : null

    const isPlayer = userDecoded && userDecoded.user.role === "player";

    // const isOrganizer = userC && userC.user.role === "organizer";

    const isAdmin = userDecoded && userDecoded.user.role === "admin";

    const isOrganizer = (ownerId) => {
        return ownerId === userDecoded.user.sggId && userDecoded.user.role === "organizer";
    }

    useEffect(() => {
        if (userC){
            setUserDecoded(jwtDecode(userC.token))
    }}
    , [userC])

    return (
        <userContext.Provider value={{ userC: userDecoded, setUserC, isPlayer, isOrganizer, isAdmin }}>
            {children}
        </userContext.Provider>
    )
}

export default UserProvider;