// para acceder una única vez al process.env
const processenv = process.env;

// constantes para extraer del .env
const API = processenv.REACT_APP_API;

// export del objeto config con valores
export const config = {
    api: API
}


