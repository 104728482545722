import { React, useContext } from 'react';
import "./Home.css";
import { Container, Box, Grid } from '@mui/material'
import { userContext } from '../../context/UserProvider';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const { userC } = useContext(userContext);
    const navigate = useNavigate();

    return (
        <Container className='home'>
            <Grid container spacing={2} style={{display:{xs: 'flex'}}}>
                <Grid item xs={12} sm={5}>
                    <Box className='home-img' sx={{}}>
                        <img src="https://i.imgur.com/lSPkgJM.png" alt="logo" />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Box className='home-info'>
                        <h1> EventFlow </h1>
                        <Box>
                            <p> EventFlow es una herramienta integral que agiliza y mejora la experiencia de los torneos y eventos de esports, fomentando la competitividad y la comunidad en el mundo de los videojuegos. </p>
                            <p> Diseñada para satisfacer las necesidades de organizadores y participantes, esta web proporciona un entorno centralizado para registrar jugadores, administrar y programar torneos, gestionar setups, comunicarse con los participantes, entre otras excepcionales funcionalidades. </p>
                            <p> Con una interfaz intuitiva y personalizable, la plataforma ofrece características como seguimiento de puntuaciones, estadísticas y generacion de reportes en tiempo real. </p>
                        </Box>
                        { !userC && <button onClick={() => navigate("/register")}>
                            Registrarse
                        </button> }
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );

}

export default Home